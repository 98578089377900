import { createRouter, createWebHistory } from 'vue-router';

import ArticleGenerator from '../views/chat/ArticleGenerator.vue'
import TransparentModal from "@/components/TransparentModal";

const routes = [
  { path: '/', component: ArticleGenerator },
  { path: '/login', component: TransparentModal },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

<template>
    <div class="modal-overlay" >
        <div class="modal-content" @click.stop>
            <div class="modal-header">
                <h3>登录</h3>
                <button class="close-button" @click="closeModal">X</button>
            </div>
            <div class="modal-body">
                <!-- 在这里添加登录表单或其他内容 -->
                <p>请登录以继续。</p>
                <!-- 例如：用户名和密码输入框 -->
            </div>
            <div class="modal-footer">
                <!-- 登录按钮 -->
                <button @click="login">登录</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TransparentModal',
        data() {
            return {
                isVisible: false
            };
        },
        methods: {
            openModal() {
                this.isVisible = true;
            },
            closeModal() {
                this.isVisible = false;
            },
            login() {
                // 处理登录逻辑
                this.closeModal();
            }
        }
    };
</script>

<style scoped>
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background: white;
        color: black;
        padding: 20px;
        border-radius: 5px;
        width: 100%;
        max-width: 400px; /* 模态框的最大宽度 */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .close-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .modal-footer {
        text-align: right;
        margin-top: 20px;
    }
</style>
